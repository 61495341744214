<template>
  <q-input
    :label="label"
    :rules="rules"
    :mask="mask"
    clear-icon="close"
    outlined
    dense
    clearable
    lazy-rules
    hide-bottom-space
  >
    <template v-slot:prepend v-if="icon">
      <q-icon :name="icon" size="xs" />
    </template>

    <template v-slot:append>
      <q-icon name="access_time" class="cursor-pointer">
        <q-popup-proxy transition-show="scale" transition-hide="scale">
          <q-time v-model="dateSelected">
            <div class="row store-center justify-end">
              <q-btn v-close-popup label="Close" color="primary" flat />
            </div>
          </q-time>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'FormDate',
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: null
    },
    mask: {
      type: String,
      default: 'time'
    }
  },
  setup(props, { emit }) {
    const dateSelected = computed({
      get() {
        return props.value
      },
      set(value) {
        emit('input', value)
      }
    })

    return {
      dateSelected
    }
  }
}
</script>
