<template>
  <q-editor
    min-height="24rem"
    max-height="24rem"
    :placeholder="placeholder"
    :disable="disabled"
    :toolbar="[
      [
        {
          label: $q.lang.editor.align,
          icon: $q.iconSet.editor.align,
          fixedLabel: true,
          list: 'only-icons',
          options: ['left', 'center', 'right', 'justify']
        }
      ],
      ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
      [
        {
          label: $q.lang.editor.formatting,
          icon: $q.iconSet.editor.formatting,
          list: 'no-icons',
          options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
        },
        {
          label: $q.lang.editor.fontSize,
          icon: $q.iconSet.editor.fontSize,
          fixedLabel: true,
          fixedIcon: true,
          list: 'no-icons',
          options: [
            'size-1',
            'size-2',
            'size-3',
            'size-4',
            'size-5',
            'size-6',
            'size-7'
          ]
        },
        {
          label: $q.lang.editor.defaultFont,
          icon: $q.iconSet.editor.font,
          fixedIcon: true,
          list: 'no-icons',
          options: [
            'default_font',
            'arial',
            'arial_black',
            'comic_sans',
            'courier_new',
            'impact',
            'lucida_grande',
            'times_new_roman',
            'verdana'
          ]
        }
      ],
      ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
      ['token', 'hr', 'link', 'custom_btn'],
      ['undo', 'redo'],
      ['fullscreen', 'viewsource']
    ]"
    :fonts="{
      arial: 'Arial',
      arial_black: 'Arial Black',
      comic_sans: 'Comic Sans MS',
      courier_new: 'Courier New',
      impact: 'Impact',
      lucida_grande: 'Lucida Grande',
      times_new_roman: 'Times New Roman',
      verdana: 'Verdana'
    }"
  />
</template>

<script>
export default {
  name: 'FormEditor',
  inheritAttrs: true,
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.q-editor__content) {
  ul,
  ol {
    display: block;
    list-style: disc outside none;
    margin: 1em 0;
    padding: 0 0 0 40px;
  }

  ol {
    list-style-type: decimal;
  }

  a {
    @apply text-primary;
    text-decoration: underline;
    cursor: auto;
  }
}
</style>
