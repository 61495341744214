<template>
  <q-input
    :class="inputClass"
    :type="inputType"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    clear-icon="close"
    dense
    clearable
    lazy-rules
    hide-bottom-space
    @input="updateValue($event.target.value)"
  >
    <template v-slot:prepend v-if="icon">
      <q-icon :name="icon" size="xs" />
    </template>

    <template v-slot:append v-if="password">
      <q-icon
        :name="showPasswordIcon"
        @click="togglePassword"
        class="cursor-pointer"
        size="xs"
      />
    </template>
  </q-input>
</template>

<script>
import { ref, computed } from 'vue'

export default {
  name: 'FormInput',
  inheritAttrs: true,
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: null
    },
    password: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const inputType = ref(props.password ? 'password' : 'text')
    const showPassword = ref(false)
    const showPasswordIcon = ref('visibility')

    const inputClass = computed(() => {
      return ['full-width']
    })

    const togglePassword = () => {
      showPassword.value = !showPassword.value
      inputType.value = showPassword.value ? 'text' : 'password'
      showPasswordIcon.value = showPassword.value
        ? 'visibility_off'
        : 'visibility'
    }

    const updateValue = value => {
      emit('input', value)
    }

    return {
      inputType,
      showPassword,
      showPasswordIcon,
      inputClass,
      togglePassword,
      updateValue
    }
  }
}
</script>
