<template>
  <q-file
    :class="inputClass"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    :filter="filter"
    :counter-label="counterLabel"
    clear-icon="close"
    counter
    outlined
    dense
    clearable
    lazy-rules
    hide-bottom-space
    @rejected="onFileError"
  >
    <template v-slot:prepend>
      <q-icon name="attach_file" size="xs" />
    </template>
  </q-file>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'FormFile',
  inheritAttrs: true,
  props: {
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const inputClass = computed(() => {
      return ['full-width']
    })

    const updateValue = value => {
      emit('input', value)
    }

    const onFileError = rejectedEntries => {
      emit('onFileError', rejectedEntries)
    }

    const counterLabel = ({ totalSize }) => {
      return totalSize
    }

    return {
      inputClass,
      updateValue,
      onFileError,
      counterLabel
    }
  }
}
</script>
